import Link from 'next/link';
import type { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import MainHeader from '../components/UI/Headers/Header';

import styles from '../styles/errors/errorPage.module.scss';

const Page404: NextPage = () => {
  const { t } = useTranslation('error-page');
  return (
    <>
      <MainHeader isSidebarOpened={false} />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t('404.title')}</h1>
        <p className={styles.description}>{t('404.description')}</p>
        <Link href={'/'}>
          <a className={styles.link}>{t('404.back_link')}</a>
        </Link>
      </div>
    </>
  );
};
export const getStaticProps = async ({ locale = 'en' }) => {
  const translations = await serverSideTranslations(locale, [
    'error-page',
    'dialogs',
    'search',
    'common',
    'duplicates',
  ]);
  return {
    props: {
      ...translations,
    },
  };
};
export default Page404;
